const countries = [
    { name: "United States", code: "US" },
    { name: "Canada", code: "CA" },
    { name: "United Kingdom", code: "GB" },
    { name: "Australia", code: "AU" },
    { name: "India", code: "IN" },
    { name: "Brazil", code: "BR" },
    { name: "Germany", code: "DE" },
    { name: "France", code: "FR" },
    { name: "Japan", code: "JP" },
    { name: "Mexico", code: "MX" },
    { name: "China", code: "CN" },
    { name: "Italy", code: "IT" },
    { name: "Spain", code: "ES" },
    { name: "Russia", code: "RU" },
    { name: "South Korea", code: "KR" },
    { name: "South Africa", code: "ZA" },
    { name: "Argentina", code: "AR" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Turkey", code: "TR" },
    { name: "Netherlands", code: "NL" },
    { name: "Switzerland", code: "CH" },
    { name: "Sweden", code: "SE" },
    { name: "Poland", code: "PL" },
    { name: "Belgium", code: "BE" },
    { name: "Indonesia", code: "ID" },
    { name: "Thailand", code: "TH" },
    { name: "Philippines", code: "PH" },
    { name: "Vietnam", code: "VN" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "Nigeria", code: "NG" },
    { name: "Israel", code: "IL" },
    { name: "Egypt", code: "EG" },
    { name: "Malaysia", code: "MY" },
    { name: "Norway", code: "NO" },
    { name: "Ireland", code: "IE" },
    { name: "New Zealand", code: "NZ" },
    { name: "Greece", code: "GR" },
    { name: "Portugal", code: "PT" },
    { name: "Pakistan", code: "PK" },
    { name: "Bangladesh", code: "BD" },
    { name: "Denmark", code: "DK" },
    { name: "Hungary", code: "HU" },
    { name: "Finland", code: "FI" },
    { name: "Romania", code: "RO" },
    { name: "Chile", code: "CL" },
    { name: "Colombia", code: "CO" },
    { name: "Peru", code: "PE" },
    { name: "Austria", code: "AT" },
    { name: "Czech Republic", code: "CZ" },
  ];
  
  export default countries;
import React from "react";

const Amount = ({ amounts, selectedAmount, customAmount, setCustomAmount, setSelectedAmount, onNext }) => {
  const handleAmountClick = (amount) => {
    setSelectedAmount(amount === "other" ? "other" : amount);
    setCustomAmount("");
  };

  const handleCustomAmountChange = (e) => {
    // Ensure no negative amounts
    const value = Math.max(0, parseFloat(e.target.value) || 0);
    setCustomAmount(value);
  };

  return (
    <div>
      <h2 className="font-cairo text-customGray2 font-bold uppercase mb-4">Amount</h2>
      <div className="grid grid-cols-3 gap-2 mb-4">
        {amounts.map((amount, index) => (
          <button
            key={index}
            className={`rounded border-[1px_solid_#C0C8D7] bg-[#E7EBF7] p-2 text-center font-cairo font-medium cursor-pointer hover:bg-blue-200 ${
              selectedAmount === amount ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => handleAmountClick(amount)}
          >
            ${amount}
          </button>
        ))}
        <button
          className={`rounded border-[1px_solid_#C0C8D7] bg-[#E7EBF7] p-2 text-center font-cairo font-medium cursor-pointer hover:bg-blue-200 ${
            selectedAmount === "other" ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handleAmountClick("other")}
        >
          Other Amount
        </button>
      </div>
      {selectedAmount === "other" && (
        <div className="relative">
          <span className="absolute left-2 top-2 text-gray-500">$</span>
          <input
            type="number"
            className="w-full p-2 border rounded pl-6" // Add padding-left to accommodate $
            placeholder="Enter custom amount"
            value={customAmount}
            onChange={handleCustomAmountChange}
            min="0"
          />
        </div>
      )}
      <button onClick={onNext} className="bg-blue-500 text-white py-2 px-4 rounded w-full mt-4">
        Next
      </button>
    </div>
  );
};

export default Amount;
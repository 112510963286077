import React from "react";
import carousel1 from "../../assets/images/carousel/carousel1.png";
import carousel2 from "../../assets/images/carousel/carousel2.png";
// import carousel3 from "../../assets/images/carousel/carousel3.png";
// import carousel4 from "../../assets/images/carousel/carousel4.png";
// import carousel5 from "../../assets/images/carousel/carousel5.png";
// import carousel6 from "../../assets/images/carousel/carousel6.png";
// import carousel7 from "../../assets/images/carousel/carousel7.png";
// import carousel8 from "../../assets/images/carousel/carousel8.png";
const CarouselProps = () => {
  const carouselItems = [
    {
      image: carousel1,
      title: "Urgent Gaza Relief",
      description:
        "Our \"Gaza Relief and Recovery\" campaign aims to address urgent humanitarian needs and support long-term recovery efforts in Gaza. The funds raised will primarily focus on immediate relief, including providing essential medical supplies, food, medical treatment, clean water, and other necessities for families affected by the conflict. Additionally, our campaign will support rebuilding healthcare facilities and providing long-term support through impactful programs and projects to support the needs of children and the health sector in Gaza. It will also allocate resources towards trauma counseling, mental health support, and other initiatives for children affected by the conflict, aiming to foster healing and resilience within the community.",
    },
    {
      image: carousel2,
      title: "Urgent Lebanon Relief ",
      description:
        "Lebanon is enduring an unimaginable crisis. Innocent children and families are caught in the devastation of relentless bombing campaigns, forced from their homes and stripped of even the most basic necessities. They need shelter, medicine, medical equipment, food, and hygiene supplies—without these essentials, countless lives are at serious risk. PCRF is fully mobilized on the ground, ready to respond. With our long-standing programs and trusted team, we are delivering life-saving aid where it’s needed most. Your support isn’t just important—it’s urgent. With our presence firmly established in Lebanon, PCRF teams are prepared to act, ensuring displaced children and families receive the care and resources they desperately need.",
    },
    // {
    //   image: carousel3,
    //   title: "PCRF Patients Receive Treatment in Spain and Belgium",
    //   description:
    //     "We are grateful to share that our brave patients have arrived in Spain and Belgium for the care they need. Sixteen patients are being treated in Spain and four in Belgium, thanks to the support of the governments of Egypt, Spain, Belgium, Slovakia, PCRF, WHO, and the EU Commission Civil Protection & Humanitarian Aid. Led by Tareq Hailat, PCRF’s Treatment Abroad team continues to lead similar missions in Europe. Many more children urgently need evacuation and medical treatment. Your ongoing support is crucial to their recovery.",
    // },
    // {
    //   image: carousel4,
    //   title: "Central Gaza Field Hospital Nears Completion",
    //   description:
    //     "We are nearing the completion of our second Field Hospital in central Gaza, a pivotal development in partnership with the World Health Organization (WHO) and Médecins Sans Frontières (MSF). As we approach the final stages, our efforts are focused on crucial finishing touches including hospital interiors, water desalination processes, and comprehensive WASH (Water, Sanitation, and Hygiene) works. This healthcare facility, soon to be managed by Al Aqsa Governmental Hospital, will provide 112 patient beds, emergency wards, surgical suites, an ICU, outpatient clinics, and much more. These advancements ensure that we can offer immediate, comprehensive medical care to those in urgent need.",
    // },
    // {
    //   image: carousel5,
    //   title: "Food Parcel Distribution for Displaced Families ",
    //   description:
    //     "In the midst of relentless conflict and blockade, our PCRF teams remain steadfast, delivering critical food parcels to displaced families in central Gaza, where food scarcity is a daily struggle. Our mission goes beyond providing sustenance; we are dedicated to sustaining hope and resilience within these communities.",
    // },
    // {
    //   image: carousel6,
    //   title: "Diaper and Infant Formula Distribution for Displaced Families",
    //   description:
    //     "We at PCRF continue our mission of serving humanity by distributing hundreds of diaper packs and infant formula to displaced families in central Gaza. These efforts are a shining beacon of hope amidst the ongoing conflict that has ravaged these areas. The war has exacerbated food famine and malnutrition, putting the lives of vulnerable infants and children at even greater risk.",
    // },
    // {
    //   image: carousel7,
    //   title: "Vegetable Distribution for Displaced Families",
    //   description:
    //     "In the face of ongoing conflict, our teams at the Palestine Children’s Relief Fund (PCRF) are tirelessly aiding displaced families in central Gaza. We’ve recently distributed vegetable packs to help feed thousands of families and ensure they receive the essential nutrition they desperately need. Our urgent Gaza relief projects continue to provide unwavering support to those who need it most. Together, we can make a lifesaving difference.",
    // },
    // {
    //   image: carousel8,
    //   title: "Jamal's Journey to Recovery",
    //   description:
    //     "In the midst of relentless conflict and blockade, our PCRF teams remain steadfast, delivering critical food parcels to displaced families in central Gaza, where food scarcity is a daily struggle. Our mission goes beyond providing sustenance; we are dedicated to sustaining hope and resilience within these communities.",
    // },
  ];
  return carouselItems;
};

export default CarouselProps;

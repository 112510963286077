import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import PaymentDetailsForm from "./payments/PaymentDetailsForm";

const Payment = ({ stripePromise, clientSecret, formData, isProcessing, setIsProcessing, navigate }) => {
  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <PaymentDetailsForm
        clientSecret={clientSecret}
        formData={formData}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
        navigate={navigate}
      />
    </Elements>
  );
};

export default Payment;
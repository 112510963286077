import "./App.css";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom"; // Added `useLocation`
import ContactUs from "./pages/ContactUs";
import { Home } from "./pages/Home";
import Navbar from "./components/Navbar";
import Donate from "./pages/donate/Donate";
import Footer from "./pages/Footer";
import Mission from "./pages/Mission";
import ThankYou from "./pages/ThankYou";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from "./pages/Popup";

function AppContent() {
  const { pathname } = useLocation(); // Destructure `pathname`

  return (
    <div className="App">
      {/* Conditionally render the Popup on pages except the Donate page */}
      {pathname !== "/donate" && <Popup />}
      <Navbar />
      <ToastContainer position="top-center" autoClose={3000} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/thank-you" element={<ThankYou />} />
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
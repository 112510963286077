import React, { useState, useEffect } from "react";
import popupImage from "../assets/images/popup.png";

const Popup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show popup as soon as the page loads
    setIsVisible(true);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 max-w-xs sm:max-w-md text-center relative w-11/12 sm:w-auto">
          <button
            onClick={handleClose}
            className="absolute top-3 right-3 text-gray-600 hover:text-gray-900 text-xl font-bold"
          >
            &times;
          </button>
          <img
            src={popupImage} 
            alt="Urgent Humanitarian Care"
            className="rounded-lg mb-4 w-full h-auto max-h-40 sm:max-h-96 object-contain"
          />
          <h2 className="text-lg font-bold text-gray-800 mb-3">
            Help Provide Urgent Humanitarian Care for Gaza's Children
          </h2>
          <p className="text-base sm:text-lg font-bold text-gray-800 mb-3">
            Amidst the Unfolding Crisis in Gaza, Over One Million Children Hang
            in the Balance.
          </p>
          <p className="text-sm sm:text-base text-gray-600 mb-4">
            PCRF is the primary humanitarian organization in Palestine,
            delivering crucial and life-saving medical relief and humanitarian
            aid where it is needed most. Access to essential resources like
            food, clean water, and healthcare is severely limited.{" "}
            <span className="font-semibold">Urgent action</span> is needed.
            With your support, we can ensure that the over one million children
            of Gaza receive the urgent care they need and deserve.
          </p>
          <a
            href="/donate"
            className="bg-blue-600 text-white text-sm sm:text-base font-semibold px-4 py-2 rounded hover:bg-blue-700 transition duration-300 block w-full sm:w-auto"
            onClick={handleClose} 
          >
            Donate Today: Click Here To Make A Difference
          </a>
        </div>
      </div>
    )
  );
};

export default Popup;
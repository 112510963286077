import React from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

const PaymentDetailsForm = ({ clientSecret, isProcessing, setIsProcessing, navigate }) => {
  const stripe = useStripe();
  const elements = useElements();
  const returnUrl = process.env.REACT_APP_RETURN_URL || "http://localhost:3000/thank-you";

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !clientSecret) return;

    setIsProcessing(true);


    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl, 
      },
    });

    if (error) {
      console.error("Payment failed:", error.message);
      toast.error("Payment failed. Please try again.");
    } else {
      toast.success("Payment successful!");
      navigate("/thank-you");
    }

    setIsProcessing(false);
  };

  // Ensure the clientSecret is available
  if (!clientSecret) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="font-cairo text-customGray2 font-bold uppercase mb-4">Payment Details</h2>
      
      {/* Render the Express Checkout Element */}
      <PaymentElement
        options={{
        }}
      />

      <div className="flex justify-between mt-4">
        <button
          type="submit"
          disabled={!stripe || isProcessing}
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          {isProcessing ? "Processing..." : "Pay Now"}
        </button>
      </div>
    </form>
  );
};

export default PaymentDetailsForm;
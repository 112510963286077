import React from "react";

const Information = ({ formData, handleInputChange, handleCountryChange, countries, onBack, onNext }) => {
  return (
    <div>
      <h2 className="font-cairo text-customGray2 font-bold uppercase mb-4">Your Information</h2>
      <input
        type="text"
        name="firstName"
        placeholder="First Name"
        className="mb-2 p-2 w-full border rounded"
        value={formData.firstName}
        onChange={handleInputChange}
      />
      <input
        type="text"
        name="lastName"
        placeholder="Last Name"
        className="mb-2 p-2 w-full border rounded"
        value={formData.lastName}
        onChange={handleInputChange}
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        className="mb-2 p-2 w-full border rounded"
        value={formData.email}
        onChange={handleInputChange}
      />
      <select
        name="country"
        value={formData.country}
        onChange={handleCountryChange}
        className="mb-4 p-2 w-full border rounded"
      >
        <option value="">Select Country</option>
        {countries.map((country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>
      <div className="flex justify-between">
        <button onClick={onBack} className="bg-gray-300 text-black py-2 px-4 rounded">
          Back
        </button>
        <button onClick={onNext} className="bg-blue-500 text-white py-2 px-4 rounded">
          Next
        </button>
      </div>
    </div>
  );
};

export default Information;
import React from "react";
import image1 from "../../assets/images/card/card1.png";
import image2 from "../../assets/images/card/card2.png";
import image3 from "../../assets/images/card/card3.png";
import image4 from "../../assets/images/card/card4.png";
import image5 from "../../assets/images/card/card5.png";
import image6 from "../../assets/images/card/card6.png";
import image7 from "../../assets/images/card/card7.png";
import image8 from "../../assets/images/card/card8.png";

const Card2Props = () => {
  const cards = [
    {
      image: image1,
      title: "Humanitarian Response in the West Bank: Supporting...",
      text: "As part of our ongoing relief efforts in the West Bank, we’ve implemented two essential projects in the Jericho ar... ",
    },
    {
      image: image2,
      title: "Fresh Food Support for Youth in PCRF's Amputee Pro...",
      text: "Through our PCRF Amputee Program, youth who have lost limbs are receiving fresh, nutritious food to support their well-b...",
    },
    {
      image: image3,
      title: "School Supplies and Tutoring at PCRF’s Pediatric C..",
      text: "This Cancer Awareness Month, at the PCRF Huda Al Masri Pediatric Cancer Department in the West Bank, we're distribut...",
    },
    {
      image: image4,
      title: "Story of Empathy and Change: Introducing Never For...",
      text: "We are excited to introduce Never Forget Omar, a beautifully written children's book by  mental health educator... ",
    }, {
      image: image5,
      title: "A Lifeline of Nourishment and Healthcare for the D...",
      text: "PCRF, in partnership with Give Palestine, is making a profound difference in the lives of the orphans at Al-Raja’a... ",
    }, {
      image: image6,
      title: "Providing School Supplies: PCRF's Support for Stud...",
      text: "This week, our PCRF teams distributed school bags with stationery to hundreds of students in need across the West Bank....",
    }, {
      image: image7,
      title: "Life-Changing Medical Care Through PCRF's Medical...",
      text: "Three refugee children in Lebanon received life-changing medical care through the PCRF One-Time-Sponsorship (OTS) progra...",
    }, {
      image: image8,
      title: " Restoring Vision and Hope: PCRF's Eyeglasses Progr...",
      text: "The PCRF team is on the ground in central Gaza, providing life-changing support through our ongoing One-Time-Sponsorship...",
    },
  ];
  return cards;
};

export default Card2Props;

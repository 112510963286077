import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import countries from "../../constants/countries";

import ProgressBar from "../../components/ProgressBar";


import Amount from "./Amount";
import Payment from "./Payment";
import Information from "./Information";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


const Donate = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [step, setStep] = useState(1);
  const [customAmount, setCustomAmount] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    billingZip: "",
  });

  const navigate = useNavigate();
  const amounts = [50, 100, 200, 500, 1000];

  // Fetch the client secret from the backend
  useEffect(() => {
    if (step === 3 && selectedAmount) {
      const amount =
        selectedAmount === "other" ? customAmount*100 : selectedAmount * 100; // Convert to cents

      fetch(`${process.env.REACT_APP_API_URL}/create-payment-intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include', 
        body: JSON.stringify({ amount, currency: "usd" }),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret))
        .catch((error) =>
          console.error("Error fetching client secret:", error)
        );
    }
  }, [step, selectedAmount, customAmount]);

  return (
    <div className="h-[972px] flex flex-col items-center w-full box-border">
      <div className="flex justify-center mb-4">
        <div
          className={`px-4 py-2 border-b-4 ${
            step === 1 ? "border-blue-500" : "border-gray-300"
          }`}
        >
          1. Amount
        </div>
        <div
          className={`px-4 py-2 border-b-4 ${
            step === 2 ? "border-blue-500" : "border-gray-300"
          }`}
        >
          2. Your Information
        </div>
        <div
          className={`px-4 py-2 border-b-4 ${
            step === 3 ? "border-blue-500" : "border-gray-300"
          }`}
        >
          3. Payment Details
        </div>
      </div>

      <div className="shadow-[0px_1px_3px_0px_rgba(47,73,117,0.2),0px_2px_2px_0px_rgba(47,73,117,0.12),0px_0px_2px_0px_rgba(47,73,117,0.14)] rounded-[4px] border-[1px_solid_#E6E9EE] bg-[#FFFFFF] m-[0_48px_0_0] flex flex-col p-[20px_1px_20px_0] w-[440px] h-auto box-sizing-border px-10 py-5">
        {step === 1 && (
         <Amount
         amounts={amounts}
            selectedAmount={selectedAmount}
            customAmount={customAmount}
            setCustomAmount={setCustomAmount}
            setSelectedAmount={setSelectedAmount}
            onNext={() => setStep(2)}
         />
        )}
        {step === 2 && (
           <Information
           formData={formData}
           handleInputChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
           handleCountryChange={(e) => setFormData({ ...formData, country: e.target.value })}
           countries={countries}
           onBack={() => setStep(1)}
           onNext={() => setStep(3)}
         />
       )}
       {step === 3 && clientSecret && (
         <Payment
           stripePromise={stripePromise}
           clientSecret={clientSecret}
           formData={formData}
           isProcessing={isProcessing}
           setIsProcessing={setIsProcessing}
           navigate={navigate}
         />
       )}
     </div>
      <ProgressBar
        raisedAmount={32884568.05}
        goalAmount={35000000}
        percentageRaised={96}
        donors={10257}
      />
    </div>
  );
};

export default Donate;
